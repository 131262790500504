@import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,200&display=swap');

body {
    font-family: 'Arvo', serif;
    background-color: var(--bg-main);
}

.carousel{
    opacity: 0.8;
}

.carousel-inner img {
    height: 500px;
    background-size: cover;
    background-position: center center;
}

.carousel-control-prev-icon, .carousel-control-prev-icon {
    width: 50px;
    height: 50px;
}
   
.carousel-control-next-icon {
    width: 50px;
    height: 50px;
}

.carousel-indicators [data-bs-target] {
    height: 5px !important;
}

.carousel-caption > p{
    color: transparent;
}

.carousel-item:hover .carousel-caption > p{
    opacity: 0.7;
    color: var(--p-color);
    background-color: var(--bg-main);
    transition: all 0.4s ease-in;
}

.custom-button {
    background-color: transparent; 
    border: 2px solid var(--btn-home-bc);
    border-radius: 50%;
    width: 50px; 
    height: 50px;
    display: flex; 
    align-items: center; 
    justify-content: center; 
    transition: all 0.3s ease;
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute; 
    top: 50%; 
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: 2px solid var(--btn-home-bc);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.carousel-control-prev {
    left: 10px;
}

.carousel-control-next {
    right: 10px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: var(--accordion-open); 
    border-radius: 50%; 
    width: 30px; 
    height: 30px; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
}


.custom-button:hover,
.carousel-control-prev:hover,
.carousel-control-next:hover {
    background-color: var(--btn-home-bc);
    color: var(--accordion-open);
}


.Home2 {
    padding: 5% 20%;
    background-color: var(--bg-home-2);
    text-align: center;
}

.Home2 > h1{
    color: var(--bg-text);
    -webkit-text-stroke: 1px var(--bg-text);
    word-spacing: 5px;
    letter-spacing: 1px;
}

.Home2 > p {
    line-height: 2;
    color: var(--p-color);
    margin: 2.5% auto;
}

.btn {
    margin: 2.5% auto;
}

.btn-outline-primary {
    background-color: transparent;
    border-color: var(--btn-home-bc);
    backdrop-filter: blur(15px);
}

.btn-outline-primary:hover {
    background-color: var(--btn-outline-primary-hover);
    border-color: var(--btn-home-bc);
    background-image: none;
    size: 120%;
    transform: scaleX(1.2);
}

.btn-outline-primary a{
    -webkit-text-stroke: 1px var(--a-color);
}

.btn-outline-primary:hover a{
    color: var(--btn-home-t);
    -webkit-text-stroke: 0;
}


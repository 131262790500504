
.light-theme{
    --bg-main: #ECF0F1;
    --a-color: rgb(90, 90, 249);
    --a-hover: blue;
    --p-color: #333333;
    --menu-text: rgba(15, 15, 15, 0.5);
    --link-color: #2979af;
    --nav-link-hover: blue;
    --bg-select-page: rgba(229, 224, 224, 0.9);
    --nav-box-s: rgba(73, 95, 72, 0.1);
    --svg-fill: rgba(15, 15, 15, 0.5);
    --bg-home-2: rgba(66, 66, 66, 0.1);
    --bg-text: #333333;
    --btn-home-bc: blue;
    --btn-outline-primary-hover: #2979af;
    --btn-home-t: black;
    --card-hover: rgba(53, 53, 53, 0.4);
    --bg-title: #2979af;
    --people-title: blue;
    --people-header: black;
    --accordion-btn: #2979af;
    --accordion-open: white;
    --accordion-body: white;
    --accordion-b: #BDC3C7;
    --table-color: black;
    --table-bg: #FFFFFF;
    --table-border: #BDC3C7;
    --table-header-bg: #2979af;
    --table-header-text: #FFFFFF;
    --table-row-even: #ECF0F1;
    --table-row-odd: #FFFFFF;
    --contact-svg: blue;
    
}

.dark-theme{
    --bg-main: #1C1C1C;
    --a-color: rgb(90, 90, 249);
    --a-hover: blue;
    --p-color: #ffffffe0;
    --menu-text: grey;
    --link-color: rgb(80, 129, 169);
    --nav-link-hover: blue;
    --bg-select-page: rgba(66, 66, 66, 0.8);
    --nav-box-s: rgba(73, 95, 72, 0.9);
    --svg-fill: rgba(190, 190, 190, 0.4);
    --bg-home-2: rgba(166, 166, 166, 0.9);
    --bg-text: #fff;
    --btn-home-bc: blue;
    --btn-outline-primary-hover: #2979af;
    --btn-home-t: white;
    --card-hover: rgba(170, 163, 163, 0.9);
    --bg-title: #2979af;
    --people-title: rgb(27, 148, 254);
    --people-header: white;
    --accordion-btn: #2979af;
    --accordion-open: #2C3E50;
    --accordion-body: rgb(62, 62, 62);
    --accordion-b: #636363;
    --table-color: white;
    --table-bg: #2C3E50;
    --table-border: #636363;
    --table-header-bg: #2979af;
    --table-header-text: #FFFFFF;
    --table-row-even: #1C1C1C;
    --table-row-odd: #2C3E50;
    --contact-svg: blue;
}
